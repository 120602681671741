<template>
  <v-container>
    <v-card>
      <v-card-text>
        
        <h1>
          <p class="text-center">
            <v-icon x-large>mdi-lock</v-icon>
            403 Forbidden
          </p>
        </h1>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Forbidden",
  props: {
    // msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
